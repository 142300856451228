<template>
    <div class="row">
        <div class="col-12 mb-3">
            <div class="card mb-5 mb-xl-10">
                <div class="card-header border-0 cursor-pointer">
                    <!--begin::Card title-->
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">{{ $t("pages.userDetails.title") }}</h3>
                    </div>
                    <!--end::Card title-->
                </div>
                <div class="card-body border-top p-9">
                    <div class="row">
                        <div class="col-6">
                            <div class="row mb-6">
                                <label class="col-lg-6 col-form-label fw-bold fs-6">{{ $t("pages.userDetails.name") }}</label>
                                <div class="col-lg-6 col-form-label fw-bold fs-6 text-primary">
                                    {{ this.user.userprofile.name }}
                                </div>
                            </div>
                            <div class="row mb-6">
                                <label class="col-lg-6 col-form-label fw-bold fs-6">{{ $t("pages.userDetails.firstname") }}</label>
                                <div class="col-lg-6 col-form-label fw-bold fs-6 text-primary">
                                    {{ this.user.userprofile.firstname }}
                                </div>
                            </div>
                            <div class="row mb-6">
                                <label class="col-lg-6 col-form-label fw-bold fs-6">{{ $t("pages.userDetails.email") }}</label>
                                <div class="col-lg-6 col-form-label fw-bold fs-6 text-primary">
                                    {{ this.user.email }}
                                </div>
                            </div>
                            <div class="row mb-6">
                                <label class="col-lg-6 col-form-label fw-bold fs-6">{{ $t("pages.userDetails.company") }}</label>
                                <div class="col-lg-6 col-form-label fw-bold fs-6 text-primary">
                                    {{ this.user.userprofile.companies }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row mb-6">
                                <label class="col-lg-6 col-form-label fw-bold fs-6">{{ $t("pages.userDetails.isActive") }}</label>
                                <div class="col-lg-6 col-form-label fw-bold fs-6 text-primary">
                                    {{ this.user.isActive }}
                                </div>
                            </div>
                            <div class="row mb-6">
                                <label class="col-lg-6 col-form-label fw-bold fs-6">{{ $t("pages.userDetails.userType") }}</label>
                                <div class="col-lg-6 col-form-label fw-bold fs-6 text-primary">
                                    {{ this.user.userprofile.userType }}
                                </div>
                            </div>
                            <div class="row mb-6">
                                <label class="col-lg-6 col-form-label fw-bold fs-6">{{ $t("pages.userDetails.status") }}</label>
                                <div class="col-lg-6 col-form-label fw-bold fs-6 text-primary">
                                    {{ this.user.status }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mb-3">
            <div class="card mb-5 mb-xl-10">
                <div class="card-header border-0 cursor-pointer">
                    <!--begin::Card title-->
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">{{ $t("pages.userDetails.titleBis") }}</h3>
                    </div>
                    <!--end::Card title-->
                </div>
                <Form id="kt_add_action_form" class="form" novalidate="novalidate">
                    <div class="card-body border-top p-9">
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.userDetails.participationLink") }}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <input class="form-control form-control-solid" v-model="this.user.userprofile.participationlink" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.userDetails.softConcept") }}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <input class="form-control form-control-solid" v-model="this.user.userprofile.softconcept" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                <div class="card-footer d-flex justify-content-end py-6 px-9">
                    <button @click="updateUser(this.user)" id="kt_account_profile_details_submit" class="btn btn-primary">
                        <span class="indicator-label"> {{ $t("general.update") }} </span>
                        <span class="indicator-progress">
                            {{ $t("general.pleaseWait") }}
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form } from "vee-validate";
import userApi from "@/core/services/User";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
    name: "roadmap",
    components: { Form },
    data: function () {
        return {
            user: {
                id: 0,
                email: "",
                status: "",
                isActive: "",
                userprofile: {
                    id: 0,
                    companies: "",
                    userType: "",
                    name: "",
                    firstname: "",
                    participationlink: "",
                    softconcept: "",
                },
            },
        };
    },
    methods: {
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
        getSpecificUser() {
            userApi.getSpecificUser(this.$route.params.user_id).then((response) => {
                this.user.id = response.data.user.id;
                this.user.email = response.data.user.email;
                this.user.status = response.data.user.status;
                this.user.isActive = response.data.user.is_active;
                this.user.userprofile.id = response.data.user.userprofile.id;

                userApi.getSpecificUserProfile(response.data.user.userprofile.id).then((response) => {
                    const link = this.$route.query.admin === "true" ? "/administration/users" : "/users";
                    const linkLabel = this.$route.query.admin === "true" ? this.t("pages.dashboard.users") : this.t("pages.users.title");
                    this.user.userprofile.name = response.data.userprofile.lastname;
                    this.user.userprofile.firstname = response.data.userprofile.firstname;
                    response.data.userprofile.related_companies.map((company) => {
                        this.user.userprofile.companies += company.name + ", ";
                    });
                    this.user.userprofile.companies = this.user.userprofile.companies.slice(0, -2);
                    this.user.userprofile.userType = response.data.userprofile.user_type.label;
                    this.user.userprofile.participationlink = response.data.userprofile.participationlink;
                    this.user.userprofile.softconcept = response.data.userprofile.softconcept;
                    setCurrentPageBreadcrumbs(this.user.userprofile.firstname + " " + this.user.userprofile.name, [
                        {
                            link: link,
                            label: linkLabel,
                        },
                    ]);
                });
            });
        },
        updateUser(user) {
            userApi.updateUserProfile(user.userprofile.id, this.user.userprofile).then((response) => {
                const link = this.$route.query.admin === "true" ? "/administration/users" : "/users";
                location.href = link;
            });
        },
    },
    setup() {
        const { t } = useI18n();
        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            const link = this.$route.query.admin === "true" ? "/administration/users" : "/users";
            const linkLabel = this.$route.query.admin === "true" ? this.t("pages.dashboard.users") : this.t("pages.users.title");
            setCurrentPageBreadcrumbs(this.user.userprofile.firstname + " " + this.user.userprofile.name, [
                {
                    link: link,
                    label: linkLabel,
                },
            ]);
        },
    },
    created() {
        this.getSpecificUser();
    },
});
</script>
