
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form } from "vee-validate";
import userApi from "@/core/services/User";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
    name: "roadmap",
    components: { Form },
    data: function () {
        return {
            user: {
                id: 0,
                email: "",
                status: "",
                isActive: "",
                userprofile: {
                    id: 0,
                    companies: "",
                    userType: "",
                    name: "",
                    firstname: "",
                    participationlink: "",
                    softconcept: "",
                },
            },
        };
    },
    methods: {
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
        getSpecificUser() {
            userApi.getSpecificUser(this.$route.params.user_id).then((response) => {
                this.user.id = response.data.user.id;
                this.user.email = response.data.user.email;
                this.user.status = response.data.user.status;
                this.user.isActive = response.data.user.is_active;
                this.user.userprofile.id = response.data.user.userprofile.id;

                userApi.getSpecificUserProfile(response.data.user.userprofile.id).then((response) => {
                    const link = this.$route.query.admin === "true" ? "/administration/users" : "/users";
                    const linkLabel = this.$route.query.admin === "true" ? this.t("pages.dashboard.users") : this.t("pages.users.title");
                    this.user.userprofile.name = response.data.userprofile.lastname;
                    this.user.userprofile.firstname = response.data.userprofile.firstname;
                    response.data.userprofile.related_companies.map((company) => {
                        this.user.userprofile.companies += company.name + ", ";
                    });
                    this.user.userprofile.companies = this.user.userprofile.companies.slice(0, -2);
                    this.user.userprofile.userType = response.data.userprofile.user_type.label;
                    this.user.userprofile.participationlink = response.data.userprofile.participationlink;
                    this.user.userprofile.softconcept = response.data.userprofile.softconcept;
                    setCurrentPageBreadcrumbs(this.user.userprofile.firstname + " " + this.user.userprofile.name, [
                        {
                            link: link,
                            label: linkLabel,
                        },
                    ]);
                });
            });
        },
        updateUser(user) {
            userApi.updateUserProfile(user.userprofile.id, this.user.userprofile).then((response) => {
                const link = this.$route.query.admin === "true" ? "/administration/users" : "/users";
                location.href = link;
            });
        },
    },
    setup() {
        const { t } = useI18n();
        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            const link = this.$route.query.admin === "true" ? "/administration/users" : "/users";
            const linkLabel = this.$route.query.admin === "true" ? this.t("pages.dashboard.users") : this.t("pages.users.title");
            setCurrentPageBreadcrumbs(this.user.userprofile.firstname + " " + this.user.userprofile.name, [
                {
                    link: link,
                    label: linkLabel,
                },
            ]);
        },
    },
    created() {
        this.getSpecificUser();
    },
});
